<template>
  <div id="app">
    <el-image :src="url" class="bc_logo"></el-image>
    <div class="beian">
      南京英诺唯真生物科技有限公司
      <a href="#">苏ICP备10214264号-1苏公网安备 42011102001239号 </a>
      <a href="#">技术支持：千瑞软件</a>
    </div>
    <!-- <keep-alive> -->
      <router-view />
    <!-- </keep-alive> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: require("@/assets/images/bc_logo.png"),
    };
  },
  // data:{
  // //   url:require('@/assets/images/bc_logo.png')
  //   url:"https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
  // }
};
</script>
<style>
@import url("//at.alicdn.com/t/font_3152641_0qf23z961jci.css");
@import url("./style/base.css");
@import url("./style/element-ui.css");
</style>
