import Vue from 'vue'
import VueRouter from 'vue-router'
import {Message} from 'element-ui'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)
const titleName = "英诺唯真"
const routes = [{
        path: '',
        redirect: '/login',
    },
    {
        path: '/login',
        name: 'login',
        component: () =>import('../views/Login/Login.vue'),
        meta:{
            title:`登录-${titleName}`
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home/Home.vue'),
        meta:{
            title:`首页-${titleName}`
        }

    }, {
        path: '/personal',
        name: 'personal',
        component: () => import('../views/Personal/Personal.vue'),
        meta:{
            title:`个人中心-${titleName}`
        }
    },{
        path:'/profile',
        name:'profile',
        component:()=> import('../views/Profile/Profile.vue'),
        meta:{
            title:`企业简介-${titleName}`
        }
    },
    {
        path:'/report',
        name:'report',
        component:()=> import('../views/Report/Report.vue'),
        meta:{
            title:`我的报告-${titleName}`
        }
    },
    {
        path:'/collect',
        name:'collect',
        component:()=> import('../views/Collect/Collect.vue'),
        meta:{
            title:`我的收藏-${titleName}`
        }
    },
    {
        path:'/detect',
        name:'detect',
        component:()=> import('../views/Detect/Detect.vue'),
        meta:{
            title:`我的检测-${titleName}`
        }
    },
    {
        path:'/detectInfo',
        name:'detectInfo',
        component:()=> import('../views/DetectInfo/DetectInfo.vue'),
        meta:{
            title:`我的检测-${titleName}`
        }
    },
    {
        path:'/detectLine',
        name:'detectLine',
        component:()=> import('../views/DetectLine/DetectLine.vue'),
        meta:{
            title:`我的检测-${titleName}`
        }
    },
    {
        path:'/equipment',
        name:'equipment',
        component:()=> import('../views/Equipment/Equipment.vue'),
        meta:{
            title:`我的设备-${titleName}`
        }
    },
    {
        path:'/equipmentInfo',
        name:'equipmentInfo',
        component:()=> import('../views/EquipmentInfo/EquipmentInfo.vue'),
        meta:{
            title:`我的设备-${titleName}`
        }
    },
    {
        path:'/equipmentLine',
        name:'equipmentLine',
        component:()=> import('../views/EquipmentLine/EquipmentLine.vue'),
        meta:{
            title:`我的设备-${titleName}`
        }
    },
    {
        path:'/searchReport',
        name:'searchReport',
        component:()=> import('../views/SearchReport/SearchReport.vue'),
        meta:{
            title:`报告搜索-${titleName}`
        }
    }
    
    //   {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    //   }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL || '/pc/',
    routes
})
//路由前置守卫，在路由跳转之前执行
router.beforeEach((to,from,next)=>{
    let token = sessionStorage.getItem("token")
    let operation = sessionStorage.getItem("operation")//是否是操作员 0否 1是
    let owner = sessionStorage.getItem("owner")//是否是机构管理员  0否 1 是
    let patient = sessionStorage.getItem('patient')//是否是受检者 0否 1 是
    document.title = to.meta.title
    if(token){
        //如果已登录  在想去登录页  不行
        if(to.path =="login"){
            next('/')
        }else{
            //如果是已登录的情况 判断是否去检测页面
        if(to.path.indexOf('detect') != -1){
            if(operation == 1){
                next()
            }else{
                Message.error('无查看权限')
                next('/home')
            }
        }
        if(to.path.indexOf('equipment') != -1){
            if(owner == 1){
                next()
            }else{
                Message.error('无查看权限')
                next('/home')
            }
        }
        if(to.path.indexOf('report') != -1){
            if(patient == 1){
                next()
            }else{
                Message.error('无查看权限')
                next('/home')
            }
        }
        next()
        }
    }else{
        //未登录  只能去首页 登录 和 企业简介
        if(to.path == '/profile' || to.path == '/home' || to.path == '/login'){
            next()
        }else{
            next('/login?redirect=' + to.path)
        }
    }
}
 

)
export default router




